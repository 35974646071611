<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <listitems
          :list="arrets_list"
          :qDelete="Qdelete"
          :qCreate="Qcreate"
          :qUpdate="Qupdate"
          :Get_suf="'Arret'"
          :title="'Arret'"
          :headers="headers"
          :field="'CREATED_AT'"
          :filename="'Arrets'"
          :sheetname="'Arrets'"
          :key="klist"
          :showstd="true"
          @rowselect="ArretChange"
          :add="true"
          :Update="true"
          :del="true"
          :del_disable="arret.nbarret > 0"
          :laoding="loading"
          :push="true"
        >
        </listitems>
      </v-col>
    </v-row>

    <template>
      <confirmdialog ref="confirm" />
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
const listitems = () => import("../components/ListItems.vue");
const confirmdialog = () => import("../components/ConfirmDialog.vue");

import CREATE_ARRET from "../graphql/Production/CREATE_ARRET.gql";
import UPDATE_ARRET from "../graphql/Production/UPDATE_ARRET.gql";
import DELETE_ARRET from "../graphql/Production/DELETE_ARRET.gql";
import ARRETS from "../graphql/Production/ARRETS.gql";

export default {
  components: {
    listitems,
    confirmdialog,
  },

  data: () => ({
    loading: false,
    title: "",
    klist: 400,
    arret: {},
    snackbar: false,
    snackbar_timeout: 2000,
    snackbar_text: "",
    snackbar_color: "primary",
    headers: [
      {
        text: "No.",
        value: "no",
        selected: true,
      },
      {
        text: "Type Arret",
        value: "label",
        rules: [(v) => !!v || "Type Arret obligatoire"],
        slot: "href",
        edit: true,
        type: "text",
        sm: 6,
        md: 9,
        selected: true,
      },
      {
        text: "Nb",
        value: "nbarret",
        selected: true,
      },
      {
        text: "Temps(min)",
        value: "temps",
        slot: "cur",
        align: "end",
        selected: true,
      },
    ],
    arrets_list: [],
  }),
  watch: {},
  computed: {
    Qcreate() {
      return CREATE_ARRET;
    },
    Qupdate() {
      return UPDATE_ARRET;
    },
    Qdelete() {
      return DELETE_ARRET;
    },
  },
  created() {},

  async mounted() {
    let r = await this.requette(ARRETS);
    if (r) {
      this.arrets_list = r.arrets;
      this.klist++;
    }
  },

  methods: {
    async requette(query, v) {
      this.loading = true;
      let r;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.req = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    ArretChange(item) {
      this.arret = item;
    },
  },
};
</script>
